export function useAzira() {
  function trackLandingPage(): void {
    const pixel = new Image()
    pixel.src = 'https://wdc-rtb-events.azira.com/v2/events/ct.gif?a=ET1'
    pixel.width = 1
    pixel.height = 1
  }

  function trackConversion(): void {
    const pixel = new Image()
    pixel.src = 'https://wdc-rtb-events.azira.com/v2/events/ct.gif?a=ET2'
    pixel.width = 1
    pixel.height = 1
  }

  function trackUplift(): void {
    const pixel = new Image()
    pixel.src = 'https://pixel.zprk.io/v5/pixel/Eu2ELz8f2n.gif?ssid=1'
    pixel.width = 1
    pixel.height = 1
    pixel.alt = ''
  }

  return {
    trackLandingPage,
    trackConversion,
    trackUplift
  }
}
