import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  if (import.meta.client) {
    const nuxtApp = useNuxtApp()
    // Track uplift once per session after the app is mounted
    nuxtApp.hook('app:mounted', () => {
      const { trackUplift } = useAzira()
      trackUplift()
    })
  }
})
